import { useState, useEffect } from "react";
import { Box } from "@material-ui/core";
import styled from "styled-components";

import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import "react-notifications/lib/notifications.css";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { RiLogoutBoxLine } from "react-icons/ri";
import { upload_nft } from "../../redux/actions/production";
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { getCollectionList } from "../../redux/actions/production";

const UploadNFT = ({ setFlagStep, set_flag_step_uploadNFT, set_flag_step_prepare, id, setErrorMsg }: any) => {
  const [progress, setProgress] = useState<any>(0);
  const [flagProcessBtn, setFlagProcessBtn] = useState<any>(false);
  const [collectionFlag, setCollectionFlag] = useState<any>(0);
  const [newCollectionAddress, setNewCollectionAddress] = useState<any>('');
  const [existedCollectionFlag, setExsitedCollectionFlag] = useState<any>(0);
  const [collectionList, setCollectionList] = useState<any>();
  const tempCollectionAddress = ['3asf44hb2t2ragsh3whya52', 'agsh3whya523asf44hb2t2r', '23d90jdgla09edl39slaldf'];

  const uploadNFT = () => {
    if (collectionFlag === 1 && newCollectionAddress === '') {
      NotificationManager.error("Please type new collection address!", "Empty Address!", 3000);
      return;
    }
    setFlagProcessBtn(true);
    if (flagProcessBtn === true) {
      NotificationManager.error("Please wait while processing.", "Hi.", 3000);
      return;
    }
    set_flag_step_uploadNFT(1);
    upload_nft(id, newCollectionAddress).then((res) => {
      if (res.success) {
        const timer = setInterval(() => {
          setProgress((oldProgress: any) => {
            if (oldProgress === 100) {
              setTimeout(() => {
                setFlagProcessBtn(false);
                set_flag_step_uploadNFT(2);
                setFlagStep(5);
                clearInterval(timer);
              }, 1000);
              return 100;
            }
            const diff = Math.round(Math.random() * 10);
            return Math.min(oldProgress + diff, 100);
          });
        }, res.exeTime / 100);
        return () => {
          clearInterval(timer);
        };
      } else {
        setFlagProcessBtn(false);
        set_flag_step_uploadNFT(3);
        setErrorMsg(res.error_msg);
        return;
      }
    });
  };

  useEffect(() => {
    getCollectionList().then((res) => {
      setCollectionList(res.collectionList)
    })
  }, [])


  return (
    <StyledComponent>
      <ProgressBox01>
        {
          !flagProcessBtn ?
            <SetCollectionPart01>
              <Text01>Set Collection</Text01>
              <Select01 mt={"10px"}>
                <FormControl fullWidth>
                  <Select
                    value={collectionFlag}
                    onChange={(e: any) => {
                      if (e.target.value === 0 || e.target.value === 1) {
                        setNewCollectionAddress('');
                      }
                      setCollectionFlag(e.target.value);
                    }}
                  >
                    <MenuItem value={0}>no collection</MenuItem>
                    <MenuItem value={1}>new collection</MenuItem>
                    <MenuItem value={2}>existed collection</MenuItem>
                  </Select>
                </FormControl>
              </Select01>
              {
                collectionFlag === 1 ?
                  <Input01
                    component={'input'}
                    {...{ type: 'text' }}
                    {...{ placeholder: 'Type new collection address' }}
                    {...{
                      value: newCollectionAddress
                    }}
                    onChange={e => {
                      setNewCollectionAddress((e.target as HTMLInputElement).value);
                    }}
                    mt={"10px"}
                  ></Input01>
                  : collectionFlag === 2 ?
                    <Select01 mt={"10px"}>
                      <FormControl fullWidth>
                        <Select
                          value={existedCollectionFlag}
                          onChange={(e: any) => {
                            setExsitedCollectionFlag(e.target.value)
                            setNewCollectionAddress(collectionList&&collectionList[e.target.value]);
                          }}
                        >
                          {
                            collectionList&&collectionList.map((each:any, index:any) => {
                              return (
                                <MenuItem value={index} key={index}>{each}</MenuItem>
                              )
                            })
                          }

                        </Select>
                      </FormControl>
                    </Select01> : <></>
              }
            </SetCollectionPart01> :
            <></>
        }

        <CircularProgressbar
          value={progress}

          text={flagProcessBtn && `${progress}%`}
          styles={{
            // Customize the path, i.e. the "completed progress"
            path: {
              // Path color
              stroke: `rgba(84, 195, 231, 1)`,
              // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
              strokeLinecap: "butt",
              // Customize transition animation
              transition: "stroke-dashoffset 0.5s ease 0s",
              // Rotate the path

              transformOrigin: "center center",
            },
            // Customize the circle behind the path, i.e. the "total progress"
            trail: {
              // Trail color
              stroke: "white",
              // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
              strokeLinecap: "butt",
              // Rotate the trail
              transformOrigin: "center center",
            },
            // Customize the text
            text: {
              // Text color
              fill: "white",
              // Text size
              fontSize: "25px",
              fontWeight: "bold",
            },
            // Customize background - only used when the `background` prop is true
            background: {
              fill: "#3e98c7",
            },
          }}
        />
      </ProgressBox01>
      <GeneratePart>
        <GenerateButton
          onClick={() => {
            set_flag_step_prepare(0);
            set_flag_step_uploadNFT(0);
            setFlagStep(3);
          }}
        >
          <RiLogoutBoxLine fontSize={'1.5rem'} fontWeight={'bolder'} />
          <Box display={'flex'} ml="5px">
            Back
          </Box>
        </GenerateButton>
        <GenerateButton onClick={() => uploadNFT()}>UPLOAD NFT</GenerateButton>
      </GeneratePart>
      <NotificationContainer />
    </StyledComponent>
  );
};

const StyledComponent = styled(Box)`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const ProgressBox01 = styled(Box)`
  display: flex;
  flex: 1;
  height: 50%;
  justify-content: center;
  align-items: center;
  position: relative;
  
`;

const GeneratePart = styled(Box)`
  display: flex;
  width: 100%;
  height: 50px;
  margin-top: auto;
  margin-bottom: auto;
  justify-content: center;
  align-items: center;
`;

const GenerateButton = styled(Box)`
  display: flex;
  width: 200px;
  height: 100%;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 1.3rem;
  font-weight: bold;
  background-color: #54c3e7;
  margin-left: 20px;
  margin-right: 20px;
  /* border-radius: 8px; */
  cursor: pointer;
  transition: 0.5s;
  &:hover {
    color: #176180;
    background-color: white;
  }
`;

const SetCollectionPart01 = styled(Box)`
  display: flex;
  align-items: center;
  position: absolute;

  flex-direction: column;

`
const Text01 = styled(Box)`
  display: flex;
  color: white;
  font-size: 1.5rem;
  font-weight: 600;
`;

const Select01 = styled(Box)`
  display: flex;
  /* margin-left: 10px; */
  /* border-radius: 8px; */
  height: 30px;
  align-items: center;
  font-size: 1.3rem;
  width: 200px;
  &:hover {
    transition: 0.3s;
  }
  .MuiPaper-root {
    background-color: white !important;
    color: black !important;
  }
  .MuiInput-underline:after {
    border-bottom: 2px solid white;
  }
  .MuiFormLabel-root.Mui-focused {
    color: white;
  }
  .Mui-selected {
    font-size: 1.3rem;
    font-weight: bold;
  }
  .MuiInputBase-root {
    font-size: 1.3rem;
    font-weight: bold;
  }
`;

const Input01 = styled(Box)`
  display: flex;
  height: 30px;
  outline: none;
  border: 2px solid white;
  /* border: null; */
  align-items: center;
  font-size: 1rem;
  width: 200px;
  color: #176180;
  transition: 0.3s;
  &:hover {
    border: 2px solid #54c3e7;
  }
`;

export default UploadNFT;
